import difference from 'lodash/difference'
import sampleSize from 'lodash/sampleSize'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Image from './image'

const LogoFade = ({ logos, style }) => {
  const [group, setGroup] = useState(0)
  const [firstGroup, setFirstGroup] = useState(sampleSize(logos, 6))
  const [secondGroup, setSecondGroup] = useState(sampleSize(logos, 6))

  const getLogos = (current) => sampleSize(difference(logos, current), 6)

  useEffect(() => {
    const timer = setInterval(() => {
      if (group === 0) {
        setSecondGroup(getLogos(firstGroup))
      } else {
        setFirstGroup(getLogos(secondGroup))
      }
      setGroup((group + 1) % 2)
    }, 5000)

    return () => clearInterval(timer)
  }, [firstGroup, secondGroup, group])

  return (
    <div css={[tw`relative h-40 mt-8 lg:(mt-0)`, style]}>
      {[firstGroup, secondGroup].map((grouping, i) => (
        <div
          css={[
            tw`w-full absolute grid grid-cols-3 grid-rows-2 gap-12 opacity-0
            transition transition-opacity duration-2000 ease-in-out
            lg:(gap-x-16 gap-y-12 mt-8 grid-rows-1 grid-cols-6)`,
            group === i && tw`opacity-100 `,
          ]}
          key={i}
        >
          {(grouping || []).map((logo, index) => (
            <div key={index} css={tw`h-9`}>
              <Image image={logo} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

LogoFade.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default LogoFade
